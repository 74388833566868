export function allowAction(status) {

    if (!status) return false;

    return !!~['A', 'AC', 'MD', 'F', 'MI', 'C', 'CT', 'CC'].indexOf(status);
}

const listaStatus = {

    S: {
        label: "Solicitada",
        status: "processing",
        color: "blue"
    },
    A: {
        label: "Agendado",
        status: "processing",
        color: "blue",
        action: "Reagendar vistoria",
        icon: ['fas', 'retweet-alt']
    },
    AC: {
        label: "Agendamento confirmado",
        status: "processing",
        color: "blue",
        action: "Confirmar agendamento",
        icon: ['fas', 'check']
    },
    I: {
        label: "Iniciado",
        status: "processing",
        color: "blue"
    },
    MD: {
        label: "Em andamento",
        status: "success",
        color: "green"
    },
    MA: {
        label: "Sincronização abortada no app",
        status: "error",
        color: "red"
    },
    MI: {
        label: "Iniciado",
        status: "processing",
        color: "blue"
    },
    MC: {
        label: "Em andamento",
        status: "processing",
        color: "blue"
    },
    MU: {
        label: "Em andamento",
        status: "success",
        color: "green"
    },
    RL: {
        label: "Realizando",
        status: "processing",
        color: "blue"
    },
    RE: {
        label: "Redesignado",
        status: "processing",
        color: "blue",
        action: "Redesignar vistoria",
        icon: ['fas', 'user-friends']
    },
    CD: {
        label: "Chave devolvida",
        status: "success",
        color: "green",
        action: "Devolução - chave do imóvel",
        icon: ['fas', 'key'],
    },
    CR: {
        label: "Chave retirada",
        status: "processing",
        color: "blue",
        action: "Retirada - chave do imóvel",
        icon: ['fas', 'key'],
    },
    C: {
        label: "Cancelado",
        status: "error",
        color: "red",
        action: "Cancelar vistoria",
        icon: ['fas', 'minus-circle']
    },
    CL: {
        label: "Concluído",
        status: "success",
        color: "green",
        action: "Concluir vistoria",
        icon: ['fas', 'check']
    },
    PA: {
        label: "Liberado para assinatura",
        status: "success",
        color: "green",
        action: "Assinar vistoria",
        icon: ['fas', 'file-signature']
    },
    F: {
        label: "Finalizado",
        status: "success",
        color: "green",
        action: "Finalizar vistoria",
        icon: ['fas', 'check']
    },
    AS: {
        label: "Alguém assinou",
        status: "success",
        color: "green",
        action: "Assinar vistoria",
        icon: ['fas', 'file-contract']
    },
    GL: {
        label: "Gerando laudo",
        status: "processing",
        color: "blue",
        action: "O laudo está sendo gerado em background",
        icon: ["fas", "file-edit"]
    },
    L: {
        label: "Laudo gerado",
        status: "success",
        color: "green",
        action: "Gerar laudo",
        icon: ['fas', 'file-pdf']
    },
    CT: {
        label: "Contestado",
        status: "error",
        color: "red",
        action: "Contestar vistoria",
        icon: ['fas', 'thumbs-down']
    },
    EL: {
        label: "Erro ao gerar laudo",
        status: "error",
        color: "#c91212",
        action: "Erro ao gerar laudo",
        icon: ["fas", "file-pdf"],
        panelColor: "#c91212"
    }
};

const listaTipoVistoria = {

    A: {
        label: 'Aditivo',
        color: '#FF6200'
    },
    C: {
        label: 'Conferência',
        color: '#7265e6'
    },
    S: {
        label: 'Saída',
        color: '#1890ff'
    },
    E: {
        label: 'Entrada',
        color: '#87d068'
    },
    V: {
        label: 'Venda',
        color: '#faad14'
    },
    CF: {
        label: 'Conformidade',
        color: '#ff4040'
    }
};

export {

    listaStatus,
    listaTipoVistoria
}

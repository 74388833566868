import hasProp from "ant-design-vue/lib/_util/props-util";
import http from "@/http";

export default {

    props: {

        visible: { Type: Boolean },
        data: { Type: Object }
    },
    created() {

        this.loading = true;

        this.$watch(() => this.data, (value, old) => {

            if (!value) return;
            this.id = value.status_id;
            this.ator_id = value.ator_id;

            Promise.all([

                http.get('painel/vistorias.status.obter', { params: { id: this.id } }),
                http.get('painel/vistorias.status.obterContestacao', { params: { vistoria_ator_id: this.ator_id } })

            ]).then(([{ data }, { data: contestacao }]) => {

                if (data) {

                    this.anexos = data.anexos;
                    this.descricao = contestacao.contestacao && contestacao.contestacao.id ? contestacao.contestacao.observacao : contestacao.observacao;
                }

            }).finally(() => this.loading = false).catch(err => {

                this.$error({
                    title: 'Desculpe, houve um erro!',
                    content: err.message,
                });
            });
        });

    },

    data() {

        return {

            id: undefined,
            ator_id: undefined,
            anexos: [],
            descricao: null,
            loading: false,
            isVisible: hasProp(this, 'visible') ? this.visible : false
        }
    },

    render() {

        const rowOptions = {
            props: {
                gutter: 16
            }
        }

        return (
            <div>
                <a-modal
                    ref="modal"
                    width={520}
                    visible={this.visible}
                    onCancel={() => this.$emit('visibleChange', false)}
                    keyboard={false}
                    footer={null}
                >
                    <div slot="title">
                        <g-icon icon={['fas', 'thumbs-down']} style={{ marginRight: '5px' }} />
                        Detalhes da contestação.
                        <span class="view-header-id" style={{ fontSize: '16px', marginLeft: '5px' }}>#{this.id && this.id}</span>
                    </div>
                    <a-spin spinning={this.loading} tip="Aguarde...">

                        <a-row {...rowOptions}>
                            <a-form-model-item label="Descrição da contestação">
                                <a-textarea
                                    readOnly
                                    placeholder="Informe a observação"
                                    autoSize={{ minRows: 5 }}
                                    v-model={this.descricao}
                                />
                            </a-form-model-item>
                        </a-row>
                        <a-row {...rowOptions} class="list-files">
                            <a-form-model-item label="Arquivos">

                                {!this.anexos.length && <a-empty />}

                                <div class="ant-upload-list ant-upload-list-text">
                                    {
                                        this.anexos.map((item, index) => {
                                            return (
                                                <span>
                                                    <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                                                        <div class="ant-upload-list-item-info">
                                                            <a href={item.arquivo} target="_blank" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                                                <g-icon icon={['fal', 'paperclip']} style={{ marginRight: '5px' }} />
                                                                <span style="color: #1890ff;"> {item.arquivo_original} </span>

                                                                <span class="ant-upload-list-item-card-actions" style="color: rgba(0,0,0,.45)">
                                                                    <a-tooltip title="Baixar arquivo">
                                                                        <g-icon icon={['fas', 'download']} style={{ cursor: 'pointer' }} />
                                                                    </a-tooltip>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            </a-form-model-item>
                        </a-row>
                    </a-spin>
                </a-modal>
            </div>
        )
    }
}

import moment from "moment";
import { listaStatus } from '../../util';
import Atendimento from '../atendimento';

export default {

    props: {

        visible: { Type: Boolean },
        data: { Type: Object }
    },
    data(){

        return {

            atendimento: {

                visible: false,
                data: {

                    vistoria_id: null
                }
            }
        }
    },
    methods: {

        handleAtendimento(status){

            if(!status) return;

            Object.assign(this.atendimento, {

                visible: true,
                data: {

                    vistoria_id: this.data.vistoria_id,
                    status_id: status.id
                }
            })
        }
    },
    render() {

        return (
            <div>
                <a-drawer
                    width="360"
                    visible={this.visible}
                    onClose={() => this.$emit('visibleChange', false)}
                >
                    <div slot="title">
                        <g-icon icon={['fas', 'clock']} style={{ marginRight: '5px' }} />
                        Linha do tempo
                    </div>
                    <a-timeline>
                        {
                            this.data && this.data.status.map((item, index) => {
                                let timeLine = listaStatus[item.status];

                                return (
                                    <a-timeline-item key={index} color={timeLine.color}>
                                        { timeLine.label}

                                        <p style={{ marginTop: '1em' }}>
                                            <g-icon style={{ marginRight: '3px' }} icon={['far', 'clock']} />
                                            {item.data ? moment(item.data).format('DD/MM/YYYY HH:mm') : 'Não informado.'}
                                        </p>
                                        {
                                            ["PA", "CT", "F", "CL"].includes(item.status) &&
                                                <p>
                                                    {item.atendimento ?

                                                        <a-tooltip
                                                            placement="right"
                                                            title="Atendimento já iniciado"
                                                        >
                                                            <a
                                                                onClick={this.handleAtendimento.bind(this, item)}
                                                            >
                                                                <g-icon icon={['fas', 'phone-volume']}/> Atendimento
                                                            </a>
                                                        </a-tooltip>
                                                        :
                                                        <a-tooltip
                                                            placement="right"
                                                            title="Sem mensagens. Clique para começar o atendimento"
                                                            style="color: rgba(24, 144, 255, 0.8)"
                                                        >
                                                            <a
                                                                onClick={this.handleAtendimento.bind(this, item)}
                                                            >
                                                                <span style="color: gray">
                                                                    <g-icon icon={['fas', 'phone-rotary']}/>
                                                                </span>
                                                                &nbsp;
                                                                Atendimento
                                                            </a>
                                                        </a-tooltip>
                                                    }
                                                </p>
                                        }
                                        {item.observacao && (<p><g-icon style={{ marginRight: '3px' }} icon={['far', 'comment-alt-dots']} /> {item.observacao}</p>)}
                                    </a-timeline-item>
                                );
                            })
                        }
                    </a-timeline>
                    <Atendimento
                        isVisible={this.atendimento.visible}
                        data={this.atendimento.data}
                        onVisibleChange={(visible) => this.atendimento.visible = visible}
                    />
                </a-drawer>
            </div>
        )
    }
}

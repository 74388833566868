import './index.less';
import moment from "moment";
import http from '@/http';

const mimeTypes = {

    'image': ['png', 'jpeg', 'jpg', 'gif'],
    'application': ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
}

export default {

    props: {

        isVisible: {type: Boolean},
        data: {type: Object}
    },
    created(){

        this.$watch(() => this.data, value => {

            if(!value.status_id) return

            this.loading = true;

            http.get(`/vistorias.atendimentos.listar?vistoria_id=${value.vistoria_id}&status_id=${value.status_id}`).then(({data}) => {

                this.feed = data.items;

            }).catch(err => {

                this.$error({

                    title: 'Desculpe, houve um erro!',
                    content: `${err.message}`
                });

            }).finally(() => {

                this.$nextTick(() => {

                    this.loading = false;
                    this.scrollEnd();
                })
            });
        });
    },
    data(){

        return {

            idFile: 0,
            isDisabled: false,
            loading: true,
            feed: [],
            modal: {},
            visible: false,
            upload: {

                visible: false,
                fileList: []
            },
            values: {

                message: '',
                titulo: undefined,
                descricao: undefined
            }
        }
    },
    computed: {

        session() {
            return this.$store.getters['session/user'];
        },
        renderFeed(){

            if(!this.feed.length) return [];

            let values = [];

            for(let item of this.feed){

                if(item.anexos){

                    for(let anexo of item.anexos){

                        if(!anexo.mimetype){

                            for(let key in mimeTypes){

                                if(mimeTypes[key].includes(anexo.extensao)) {

                                    anexo.mimetype = key;
                                    break
                                }
                            }
                        }
                    }
                }

                values.push({

                    anexos: item.anexos,
                    date: item.date,
                    descricao: item.descricao,
                    id: item.descricao,
                    usuario: item.usuario
                })
            }

            return values
        },
        listFiles(){

            if(!this.upload.fileList.length) return [];

            let files = [];
            const list = this.upload.fileList;

            for(let file of list) {

                if(!file.mimetype){

                    for(let key in mimeTypes){

                        if(mimeTypes[key].includes(file.extensao)) {

                            file.mimetype = key;
                            break
                        }
                    }
                }

                files.push(file);
            }

            return files;
        }
    },
    methods: {

        scrollEnd(){

            this.$nextTick(() => {

                this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight;
            })
        },
        handleDownload(data){

            if (!data) return;
            let a = document.createElement('a');
            a.setAttribute("href", `${this.modal.arquivo}`)
            a.setAttribute("target", 'new_blank')
            a.setAttribute("download", `${this.modal.arquivo_original}`);
            a.click();
        },
        dummyRequest({file, onSuccess}){

            setTimeout(() => {

                onSuccess("ok");

            }, 0);
        },
        handleChange({target: {files}}){

            let execs = [];

            for(let file of files){

                if(Math.round((file.size / 1024)) > 10000) {

                    return this.$error({

                        title: 'O tamanho máximo de cada arquivo deve ser inferior ou igual a 10MB.',
                        content: `O arquivo ${file.name} supera esse limite.`
                    });
                }

                const reader = new FileReader();

                execs.push(new Promise((res, rej) => {

                    reader.onload = function(){

                        res(this.result)
                    }

                }).then(data => {

                    return {

                        id: --this.idFile,
                        extensao: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2),
                        tamanho: file.size,
                        arquivo_original: file.name,
                        descricao: undefined,
                        titulo: undefined,
                        arquivo: data,
                        originFile: file
                    }
                }));

                reader.readAsDataURL(file);
            }

            Promise.all(execs).then((data) => {

                this.upload.fileList = this.upload.fileList.concat(data);
            })
        },
        handlePreview(data, inside){

            if(!data) return;

            if(inside) this.upload.visible = !inside;

            this.isDisabled = data.id > 0;
            this.visible = true;
            this.modal = Object.assign({}, data);
        },
        handleCancelModal(disabled){

            this.visible = false;
            if(!disabled) this.upload.visible = true;
        },
        handleEditPhoto(disabled){

            let data = Object.assign({}, this.modal);

            let file = this.upload.fileList.find(item => {if(item.id === this.modal.id) return true});
            file.descricao = data.descricao;
            file.titulo = data.titulo;

            this.visible = false;
            if(!disabled) this.upload.visible = true;
        },
        handleRemove(data){

            if(!data) return;

            let file = this.upload.fileList.find(item => {if(item.id === data.id) return true});
            this.upload.fileList.splice(this.upload.fileList.indexOf(file), 1);
        },
        handleSubmitAnexo(e){

            if(!this.upload.fileList.length){

                this.upload.visible = false;
                return;
            }

            let anexosFiles = [];
            let anexos = {inserts: []}

            anexos.inserts = this.upload.fileList.map((file, index) => {

                anexosFiles.push(file.originFile)

                return {

                    titulo: file.titulo,
                    descricao: file.descricao,
                    index
                }
            })

            let data = new FormData();

            data.append('data', JSON.stringify({

                vistoria_id:  parseInt(this.data.vistoria_id),
                status_id: parseInt(this.data.status_id),
                usuario_id: this.session.user.id,
                descricao: this.values.message.length ? this.values.message.trim() : null,
                anexos
            }));

            for(let file of anexosFiles){

                data.append('anexos', file);
            }

            this.throwSubmit(data)
            this.$nextTick(() => {

                this.upload.visible = false;
            })
        },
        handleSubmit(e){

            if(e.shiftKey) return;
            if(!this.values.message.length) return;

            this.throwSubmit({

                data: {

                    descricao: this.values.message.trim(),
                    vistoria_id: parseInt(this.data.vistoria_id),
                    status_id: parseInt(this.data.status_id),
                    usuario_id: this.session.user.id
                }
            });
        },
        throwSubmit(data){

            if(this.values.message.length > 500) return this.$error({

                title: 'Tamanho excedido!',
                content: 'A mensagem não pode ultrapassar de 500 caracteres.'
            })

            this.loading = true;

            http.post('/vistorias.atendimentos.salvar', data).then((saved) => {

                http.get(`/vistorias.atendimentos.obter?id=${saved.data.id}`).then(({data}) => {

                    this.feed.push(data);

                }).catch(err => {

                    this.$error({

                        title: 'Desculpe, houve um erro ao salvar sua mensagem!',
                        content: `${err.message}`
                    });

                }).finally(() => {

                    this.scrollEnd();
                    this.values.message = '';
                    this.upload.fileList = [];
                    this.loading = false;
                })
            })
        },
        handleDrawerClose(){

            if(this.upload.fileList.length || this.values.message.length){

                this.$confirm({
                    title: "Atenção",
                    content: "Caso feche a aba, as alterações serão perdidas!",
                    onOk:() => {

                        this.values.message = '';
                        this.upload.fileList = [];
                        this.$emit('visibleChange', false);
                    },
                    onCancel:() => {

                        return false;
                    }
                })

            } else {

                this.$emit('visibleChange', false);
            }
        },
        handleDownloadHistory() {

            this.loading = true;
            http.get('/vistorias.atendimentos.historicos.obter', { params: { status_id: this.data.status_id, vistoria_id: this.data.vistoria_id }, responseType: 'blob' }).then((data) => {

                let url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));

                if(!window.open(url, '_blank')){

                    this.$warning({
                        title: 'Ops, o navegador está bloqueando popups.',
                        content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p>
                        </div>
                    });
                }

            }).finally(() => this.loading = false);
        }
    },
    render(){

        return(

            <a-drawer
                width="600px"
                visible={this.isVisible}
                onClose={this.handleDrawerClose.bind(this)}
            >
                <div>
                    <a-spin spinning={this.loading}>
                        <div class="view">
                            <div class="view-header">
                                <div class="view-header-body">
                                    <div class="view-header-start">
                                        <g-icon icon={['far', 'phone-rotary']}/>
                                        <h1>Atendimento</h1>
                                    </div>
                                    <div class="view-header-end">
                                        {this.session.user && this.session.user.cliente && (
                                            <a-button
                                                ref="history"
                                                type="primary"
                                                onClick={this.handleDownloadHistory.bind(this)}
                                                v-show={this.feed.length}
                                            >
                                                <g-icon icon={['fas', 'download']}/>
                                                Baixar histórico
                                            </a-button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div class="body">
                                <div class="feed" ref="feed">
                                    <a-list>
                                        {this.renderFeed.map(item => {

                                            return (
                                                <a-list-item class="coment">
                                                    <a-comment
                                                        avatar={item.usuario.avatar || <div class="avatar"><g-icon icon={['fas', 'user']}/></div>}
                                                        content={item.descricao}
                                                    >
                                                        <div
                                                            slot="author"
                                                            class="header"
                                                        >
                                                            {item.usuario.nome}
                                                        </div>
                                                        <div
                                                            slot="datetime"
                                                            class="header"
                                                        >
                                                            {moment(item.date).format('HH:mm DD/MM/YYYY ')}
                                                        </div>
                                                        {item.anexos ?

                                                            <div class="anexo">
                                                                {item.anexos.map(anexo => {

                                                                    return (

                                                                        <div
                                                                            class="anexo-content"
                                                                            onClick={this.handlePreview.bind(this, anexo)}
                                                                            title={anexo.titulo}
                                                                        >
                                                                            {anexo.mimetype && anexo.mimetype === 'image'
                                                                                ?
                                                                                <img class="anexo-content-image" src={anexo.arquivo}/>
                                                                                :
                                                                                <div class="anexo-content-application">
                                                                                    <span class="application">
                                                                                        <g-icon icon={['fas', 'file-archive']}/>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            :
                                                            undefined
                                                        }
                                                    </a-comment>
                                                </a-list-item>
                                            )
                                        })}
                                    </a-list>
                                </div>
                            </div>
                            <div class="footer">
                                <div class="footer-content">
                                    <div class="footer-content-item">
                                        <a-button
                                            shape="circle"
                                            type="primary"
                                            onClick={() => this.upload.visible = true}
                                        >
                                            <g-icon icon={['fas', 'paperclip']}/>
                                        </a-button>
                                    </div>
                                    <div class="footer-content-item input">
                                        <a-textarea
                                            allowClear
                                            placeholder="Digite uma mensagem"
                                            autoSize={{maxRows: 1, minRows: 1}}
                                            onPressEnter={this.handleSubmit.bind(this)}
                                            v-model={this.values.message}
                                        />
                                    </div>
                                    <div class="footer-content-item">
                                        <a-button
                                            type="primary"
                                            shape="circle"
                                            onClick={this.handleSubmit.bind(this)}
                                        >
                                            <g-icon icon={['fas', 'arrow-right']}/>
                                        </a-button>
                                    </div>
                                </div>
                            </div>
                            <a-modal
                                visible={this.upload.visible}
                                onCancel={() => this.upload.visible = false}
                            >
                                <div class="upload-container">
                                    {this.listFiles.map(item => {

                                        return (

                                            <div class="upload-list-item">
                                                <div class="upload-list-item-picture-card">
                                                    <div>
                                                        {item.mimetype === 'image'
                                                            ?
                                                            <img class="picture-card-image" src={item.arquivo}/>
                                                            :
                                                            <g-icon icon={['fas', 'file-archive']}/>
                                                        }
                                                    </div>
                                                    <div class="actions">
                                                        <span>
                                                            <a-tooltip
                                                                placement="top"
                                                                title="Visualizar"
                                                            >
                                                                <span
                                                                    onClick={this.handlePreview.bind(this, item, true)}
                                                                >
                                                                    <g-icon icon={['fas', 'eye']}/>
                                                                </span>
                                                            </a-tooltip>
                                                        </span>
                                                        <span>
                                                            <a-tooltip
                                                                placement="top"
                                                                title="Excluir"
                                                            >
                                                                <span
                                                                    onClick={this.handleRemove.bind(this, item)}
                                                                >
                                                                    <g-icon icon={['fas', 'trash']}/>
                                                                </span>
                                                            </a-tooltip>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div class="upload-list-item">
                                        <input
                                            multiple
                                            type="file"
                                            ref="anexos"
                                            onChange={this.handleChange}
                                            style="display: none;"
                                            accept=".png, .jpg, .jpeg, .webp, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx"
                                        />
                                        <a-button
                                            class="upload-select-picture-card"
                                            onClick={() => this.$refs.anexos.click()}
                                        >
                                            <g-icon icon={['far', 'plus']}/>
                                        </a-button>
                                    </div>
                                </div>
                                <div slot="footer">
                                    <a-button
                                        type="primary"
                                        onClick={this.handleSubmitAnexo.bind(this)}
                                    >
                                        Enviar
                                    </a-button>
                                </div>
                            </a-modal>
                        </div>
                        <a-modal
                            class="anexomodal"
                            ref="modal"
                            visible={this.visible}
                            onCancel={this.handleCancelModal.bind(this, this.isDisabled)}
                        >
                            <div slot="title">
                                <g-icon icon={['fas', 'file-alt']} style={{marginRight: '5px'}}/>
                                {this.modal.id && <span class="view-header-id" style={{
                                    fontSize: '16px',
                                    marginLeft: '5px'
                                }}>#{this.modal.id}</span>}
                                Dados do anexo
                            </div>
                            <div class="container-image">
                                {this.modal.mimetype && this.modal.mimetype === 'image'
                                    ?
                                    <img class="image" src={this.modal.arquivo}/>
                                    :
                                    <div class="application">
                                        <g-icon icon={['fas', 'file-archive']}/>
                                    </div>
                                }
                            </div>
                            <a-row {...{props: {gutter: 14}}}>
                                <a-form-model-item prop="titulo" label="Título">
                                    <a-input
                                        allowClear
                                        placeholder="Informe o título"
                                        readOnly={this.isDisabled}
                                        v-model={this.modal.titulo}
                                    />
                                </a-form-model-item>
                            </a-row>
                            <a-row {...{props: {gutter: 14}}}>
                                <a-form-model-item prop="descricao" label="Descrição">
                                    <a-textarea
                                        allowClear
                                        readOnly={this.isDisabled}
                                        placeholder="Informe a observação"
                                        auto-size={{minRows: 3, maxRows: 5}}
                                        v-model={this.modal.descricao}
                                    />
                                </a-form-model-item>
                            </a-row>
                            <div slot="footer">
                                <a-button
                                    type="secondary"
                                    onClick={this.handleDownload.bind(this)}
                                    v-show={this.isDisabled}
                                >
                                    Baixar arquivo
                                </a-button>
                                <a-button
                                    type="danger"
                                    onClick={this.handleCancelModal.bind(this, this.isDisabled)}
                                >
                                    Fechar
                                </a-button>
                                <a-button
                                    type="primary"
                                    onClick={this.handleEditPhoto.bind(this, this.isDisabled)}
                                    v-show={!this.isDisabled}
                                >
                                    Salvar
                                </a-button>
                            </div>
                        </a-modal>
                    </a-spin>
                </div>
            </a-drawer>
        )
    }
}

import hasProp from "ant-design-vue/lib/_util/props-util";
import http from "@/http";
import { listaStatus } from '../../util';
import { isArray } from "@/resources/util";
import EventMixin from "@/mixins/EventMixin";
import ModalTerms from "@/components/modal-terms/modalTerms";

export default {

    mixins: [EventMixin],
    props: {

        visible: { Type: Boolean },
        data: { Type: Object, required: true },
    },
    created() {

        let someVisible;

        this.$watch(() => this.visible, is => {

            if (is && !someVisible) {

                someVisible = true;

                this.$nextTick(() => {

                    this.$onEvent(this.$refs.modal.$vnode.elm.childNodes[1].firstChild, 'animationend', () => {

                        if (!this.visible) {

                            this.resetForm();
                        }
                    })
                })
            }

            this.isVisible = is;
        });

        this.$watch(() => this.data, (value, old) => {

            if (!value) return;

            const { status, vistoria } = value;

            this.form.vistoria_id = vistoria.id;

            this.status = listaStatus[status];
            this.status.sigla = status;

            this.rules.observacao.required = status === 'CT';
        });

    },
    data() {

        return {

            loading: false,
            isVisible: hasProp(this, 'visible') ? this.visible : false,
            termosModal: false,
            aceite: false,
            rules: {

                observacao: {
                    min: 3,
                    required: false,
                    message: 'Observação precisa ser maior ou igual à 3 caracteres.'
                }
            },
            form: {

                vistoria_id: undefined,
                observacao: undefined,
                data: null,
                pagina_assinatura: false,
                config: {
                    assinar: false,
                    versao_reduzida: false,
                    cliente_assina: false,
                    cliente_representa_proprietario: false,
                    cliente_representa_proprietario_info: null
                }
            },
            status: {}
        }
    },
    methods: {
        handleVisibleChange(is) {

            this.$emit('visibleChange', is);
        },
        handleClose() {

            this.handleVisibleChange(false);
            this.$nextTick(() => {

                Object.assign(this.form, {
                    vistoria_id: undefined,
                    observacao: undefined,
                    data: null,
                    pagina_assinatura: false,
                    config: {
                        assinar: false,
                        versao_reduzida: false,
                        cliente_assina: false,
                        cliente_representa_proprietario: false,
                        cliente_representa_proprietario_info: null
                    }
                });

                this.loading = false;
            });
        },
        resetForm() {

            this.$refs.form.clearValidate();

            Object.assign(this.form, {
                vistoria_id: undefined,
                observacao: undefined,
                data: null,
                pagina_assinatura: false,
                config: {
                    assinar: false,
                    versao_reduzida: false,
                    cliente_assina: false,
                    cliente_representa_proprietario: false,
                    cliente_representa_proprietario_info: null
                }
            });

            this.aceite = false;
        },
        handleSubmit(e) {

            e.preventDefault();
            this.$refs.form.validate(valid => {

                if (valid && this.aceite) {

                    this.loading = true;
                    this.form.status = this.status.sigla;

                    if(this.status.sigla !== 'GL') delete this.form.pagina_assinatura;

                    if(!['PA', 'GL'].includes(this.status.sigla)) delete this.form.config;
                    else this.form.config.assinar = this.status.sigla === 'PA';

                    http.post('/painel/vistorias.status.salvar', { data: this.form }).then(({ data }) => {

                        if (data.id) {

                            this.$emit('submit', Object.assign({}, this.form, data));
                            this.handleClose();
                        }
                    }).catch(err => {

                        this.$error({
                            title: err.error && isArray(err.error) ? err.message : 'Atenção, houve um erro.',
                            content: (
                                err.error && isArray(err.error) ? (
                                    <ul>
                                        {err.error.map(e => {
                                            return <li><div domPropsInnerHTML={e} /></li>
                                        })}
                                    </ul>
                                ) : <span>{err.message}</span>
                            )
                        });

                    }).finally(() => {

                        this.loading = false;
                    });
                }
            });
        },
        handleTerm(val) {

            this.aceite = val;
            this.termosModal = false;
        },
        okButtonText(status) {

            switch (status) {

                case "AS":
                    return "Assinar";
                case "PA":
                    return "Liberar";
                default:
                    return "Salvar";
            }
        }
    },

    render() {

        const formProps = {

            ref: "form",
            attrs: {
                id: 'form-status-vistoria'
            },
            props: {
                model: this.form,
                rules: this.rules
            },
            on: {
                submit: this.handleSubmit
            }
        };

        let Options;

        if(this.form.config) {

            Options = (
                <div>
                    <a-checkbox v-model={this.form.config.cliente_assina}>
                        Imobiliária assina?
                    </a-checkbox>
                    <br/>
                    <a-checkbox v-model={this.form.config.cliente_representa_proprietario}>
                        Imobiliária assina em nome do(s) proprietário(s)?
                    </a-checkbox>
                    <div v-show={this.form.config.cliente_representa_proprietario}>
                        <a-alert type="info" showIcon style="margin: 4px 0 8px 0;">
                            <template slot="message">
                                Utilize essa opção quando a imobiliária assina em nome do proprietário, utilize o campo
                                abaixo para adicionar informações, como detalhes da procuração, que irão aparecer na
                                assinatura.
                            </template>
                        </a-alert>
                        <a-input placeholder="Informações sobre a assinatura"
                                 max-length={48}
                                 v-model={this.form.config.cliente_representa_proprietario_info} />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <a-modal
                    ref="modal"
                    width={420}
                    visible={this.isVisible}
                    onCancel={this.handleClose}
                    onOk={this.handleSubmit}
                    okText={this.okButtonText(this.status.sigla)}
                    maskClosable={false}
                    keyboard={false}
                    closable={!this.loading}
                    okButtonProps={{ props: { disabled: this.loading || !this.aceite } }}
                    cancelButtonProps={{ props: { disabled: this.loading } }}
                >
                    <div slot="title">
                        {this.status.icon ? <g-icon icon={this.status.icon} style={{ marginRight: '5px' }} /> : ''}
                        {this.status.action}
                        <span class="view-header-id" style={{ fontSize: '16px', marginLeft: '5px' }}>#{this.form.vistoria_id}</span>
                    </div>
                    <a-spin spinning={this.loading} tip="Aguarde...">
                        <a-form-model {...formProps}>
                            <a-row>
                                <a-alert
                                    message="Atenção"
                                    type="info"
                                    showIcon
                                >
                                    <template slot="description">
                                        {this.status.sigla === 'PA' && <span>
                                            Ao clicar em Liberar, as partes envolvidas serão avisadas que já podem
                                            acessar o painel e assinar digitalmente o laudo.
                                        </span>}
                                        {this.status.sigla === 'AS' && <span>
                                            Ao clicar em Assinar, você concorda que recebeu e que está assinando
                                            digitalmente o laudo de vistoria gerado, e concorda com os termos de uso.
                                        </span>}
                                        {this.status.sigla === 'GL' && <span>
                                            Utilizar esta opção irá finalizar o laudo sem a assinatura digital das
                                            partes envolvidas, uma vez finalizada não é possível voltar atrás e mudar
                                            esta opção!
                                        </span>}
                                    </template>
                                </a-alert>
                            </a-row>
                            <a-row v-show={this.status.sigla !== 'AS'}>
                                <a-form-model-item
                                    label="Observação"
                                    prop="observacao"
                                >
                                    <a-textarea
                                        allowClear
                                        placeholder="Informe a observação"
                                        maxLength={1000}
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        v-model={this.form.observacao}
                                    />
                                </a-form-model-item>
                            </a-row>
                            {this.status.sigla === 'PA' && <a-row>
                                <b>Opções:</b><br/>
                                <a-checkbox v-model={this.form.config.versao_reduzida}>
                                    Versão reduzida&nbsp;
                                    <a-tooltip title="Gerar laudo sem imagem de capa e sem quebra de página entre os tópicos.">
                                        <g-icon icon={['far', 'question-circle']} style="font-size: 1.2em" />
                                    </a-tooltip>
                                </a-checkbox>
                                <br/>
                                {Options}
                            </a-row>}
                            {this.status.sigla === 'GL' && <a-row>
                                <b>Opções:</b><br/>
                                <a-checkbox v-model={this.form.config.versao_reduzida}>
                                    Versão reduzida&nbsp;
                                    <a-tooltip title="Gerar laudo sem imagem de capa e sem quebra de página entre os tópicos.">
                                        <g-icon icon={['far', 'question-circle']} style="font-size: 1.2em" />
                                    </a-tooltip>
                                </a-checkbox>
                                <br/>
                                <a-checkbox v-model={this.form.pagina_assinatura}>
                                    Gerar página para assinatura manual?
                                </a-checkbox><br/>
                                <div v-show={this.form.pagina_assinatura}>
                                    {Options}
                                </div>
                            </a-row>}
                            {
                                ['PA', 'GL'].includes(this.status.sigla) ? (
                                    <a-row style="margin-top: 14px;">
                                        <a-checkbox v-model={this.aceite} />
                                        <a style="padding-left: 8px;" onClick={() => this.termosModal = true}>Eu li e concordo com os termos de serviço</a>
                                    </a-row>
                                ) : (
                                    <a-row style="margin-top: 14px;">
                                        <a-button type="link" onClick={() => this.termosModal = true}>
                                            <g-icon icon={['far', this.aceite ? 'check-square' : 'square']} />
                                            Eu li e concordo com os termos de serviço
                                        </a-button>
                                    </a-row>
                                )
                            }
                        </a-form-model>
                    </a-spin>
                </a-modal>

                <ModalTerms
                    visible={this.termosModal}
                    onAcceptTerm={(val) => this.handleTerm(val)}
                />
            </div>
        )
    }
}
